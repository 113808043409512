import React from 'react';
import { Helmet } from 'react-helmet';

export default function CustomFonts({
  data
}) {
  return (
    <>
      { data?.customHeadingFont?.fontFamilyName && data?.customBodyFont?.fontFamilyName
      && (
      <Helmet>
        <style type="text/css">
          {`
        @font-face{
                font-family:"${data?.customHeadingFont?.fontFamilyName}";
                src: url("./${data?.customHeadingFont?.fontUrl?.file.fileName}") format("truetype")} 
        
        @font-face {
                  font-family:"${data?.customBodyFont?.fontFamilyName}";
                  src:url("./${data?.customBodyFont?.fontUrl?.file.fileName}") format("truetype")}
        `}
        </style>
      </Helmet>
      )}
    </>
  );
}
