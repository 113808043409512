import React from 'react';
import { Helmet } from 'react-helmet';

export default function SEO({
  title, description, metaKeywords, metaRobotsTag
}) {
  return (
    <Helmet
      title={title}
      htmlAttributes={{
        lang: 'en-US'
      }}
    >
      <title>{title}</title>
      <meta charset="UTF-8" name="language" content="en" />
      {description ? <meta name="description" content={description} /> : null}
      {metaKeywords ? <meta name="keywords" content={metaKeywords} /> : null}
      {metaRobotsTag ? <meta name="robots" content={metaRobotsTag} /> : null}
      {title ? <meta property="og:title" content={title} /> : null}

      {description ? (
      <meta property="og:description" content={description} />
      ) : null}

      {title ? <meta name="twitter:title" content={title} /> : null}

      {description ? (
      <meta name="twitter:description" content={description} />
      ) : null}
    </Helmet>
  );
}
